// PRODUCT TYPES

export const ADD_PRODUCT = "ADD_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const FETCHED_PRODUCT = "FETCHED_PRODUCT";
export const FETCHED_MORE_PRODUCT = "FETCHED_MORE_PRODUCT";

// CART TYPES

export const ADD_TO_CART = "ADD_TO_CART";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const SET_CNT = "SET_CNT";
export const INCREASE_CNT = "INCREASE_CNT";
export const DECREASE_CNT = "DECREASE_CNT";
export const CLEAR_CART = "CLEAR_CART";

// SEVER CART TYPES
export const FETCH_BASKETS = "FETCH_BASKETS";
export const INSERT_BASKETS = "INSERT_BASKETS";
export const DELETE_BASKETS = "DELETE_BASKETS";
export const CLEAR_BASKETS = "CLEAR_BASKETS";
export const INIT_BASKETS = "INIT_BASKETS";

// WISHLIST TYPES

export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const DELETE_FROM_WISHLIST = "DELETE_FROM_WISHLIST";
export const CLEAR_WISHLIST = "CLEAR_WISHLIST";

// QUICK VIEW TYPES

export const OPEN_QUICK_VIEW = "OPEN_QUICK_VIEW";
export const CLOSE_QUICK_VIEW = "CLOSE_QUICK_VIEW";

// FILTERS TYPES

export const UPDATE_PRODUCT_FILTERS = "UPDATE_PRODUCT_FILTERS";
export const UPDATE_PRODUCT_CATEGORY = "UPDATE_PRODUCT_CATEGORY";
export const UPDATE_RATING = "UPDATE_RATING";

//  LOGIN MODAL

export const OPEN_LOGIN_MODAL = "OPEN_LOGIN_MODAL";
export const CLOSE_LOGIN_MODAL = "CLOSE_LOGIN_MODAL";

//  CONFIRM MODAL

export const OPEN_CONFIRM_MODAL = "OPEN_CONFIRM_MODAL";
export const CLOSE_CONFIRM_MODAL = "CLOSE_CONFIRM_MODAL";
export const MODIFY_CONFIRM_MODAL = "MODIFY_CONFIRM_MODAL";

//  MODAL

export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const MODIFY_MODAL = "MODIFY_MODAL";

export const OPEN_DEFAULT_MODAL = "OPEN_DEFAULT_MODAL";
export const CLOSE_DEFAULT_MODAL = "CLOSE_DEFAULT_MODAL";
export const MODIFY_DEFAULT_MODAL = "MODIFY_DEFAULT_MODAL";

//  ALERT

export const SHOW_TOAST_ALERT = "SHOW_TOAST_ALERT";

//  LOADING

export const IS_LOADING = "IS_LOADING";
export const IS_LOADED = "IS_LOADED";

// LOCAL STORAGE

export const INIT_LOCALSTORAGE = "INIT_LOCALSTORAGE";

// siteConfig_alliance
export const ALLIANCE_INFO = "ALLIANCE_INFO";

// BANNER
export const FETCH_BANNER = "FETCH_BANNER";

// default array
export const FETCH_DUMMY = "FETCH_DUMMY";

// user view
export const USER_VIEW = "USER_VIEW";

// siteconfig
export const SITE_INFO = "SITE_INFO";
