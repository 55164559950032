import Loading from "./Loading";
import ConfirmModal from "./ConfirmModal";
import ModalContainer from "./ModalContainer";
import { ToastContainer } from "react-toastify";
import DefaultModal from "./defaultModal";

const GlobalArea = () => {
  return (
    <>
      <Loading />
      <ConfirmModal />
      <DefaultModal />
      <ModalContainer />
      <ToastContainer
        newestOnTop="true"
        limit={5}
        style={{ zIndex: 99999999 }}
      />
    </>
  );
};

export default GlobalArea;
